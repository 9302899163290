<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 sidebar-org bg-custom-blue pb-5">
        <div class="row mt-3">
          <div class="col-12">
            <div v-for="(employee, index) in employees" :key="employee.id" class="employee">
              <div class="border-bottom border-dark" :class="index === 0 ? 'pb-2' : 'py-2'">
                <div class="d-inline-block">
                  <span @click="selectEmployee(employee.id)" class="employee-name" :class="selectedEmployee.id === employee.id ? 'fw-bold' : ''"
                        role="button">{{ employee.lastName }}, {{ employee.firstName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 px-5">
        <div v-if="Object.keys(selectedEmployee).length" class="row">
          <div class="col-12">
            <div class="row mt-3">
              <div class="col">
                <a class="btn btn-sm btn-outline-dark" href="#duplicateModal" data-bs-toggle="modal">&bull; {{ $t("views.permission.duplicateFrom") }}</a>
              </div>
              <div class="col text-end">
                <DocsInfoLink color="dark" target="managePermissions"></DocsInfoLink>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div class="h3">{{ selectedEmployee.lastName }}, {{ selectedEmployee.firstName }}</div>
                <div>
                  {{ selectedEmployee.email }} &bull;
                  <span v-if="selectedEmployee.role === 10387">{{ $t("views.org.employee.admin") }}</span>
                  <span v-else-if="selectedEmployee.role === 15972">{{ $t("views.org.employee.standard") }}</span>
                  <span v-else>{{ $t("views.org.employee.noAccount") }}</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="card">
                  <div class="card-header fw-bold">{{ $t("views.permission.headingCard") }}</div>
                  <div class="card-body">
                    <ul class="nav nav-tabs border-bottom" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark active" id="orgunits-tab" data-bs-toggle="tab" data-bs-target="#orgunits" type="button" role="tab" aria-controls="orgunits" aria-selected="false">{{ $t("views.permission.tabOrg") }}</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="kpis-tab" data-bs-toggle="tab" data-bs-target="#kpis" type="button" role="tab" aria-controls="kpis" aria-selected="false">{{ $t("views.permission.tabKPIs") }}</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="objectives-tab" data-bs-toggle="tab" data-bs-target="#objectives" type="button" role="tab" aria-controls="objectives" aria-selected="false">{{ $t("views.permission.tabObjectives") }}</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link text-dark" id="projects-tab" data-bs-toggle="tab" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">{{ $t("views.permission.tabProjects") }}</button>
                      </li>
                    </ul>
                    <div class="tab-content mt-2">
                      <div class="tab-pane fade show active" id="orgunits" role="tabpanel">
                        <div class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'orgunit', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#permissionEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="orgUnitPermissions.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.permission.orgUnit") }}</th>
                                <th scope="col">{{ $t("views.permission.permission") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="permission in orgUnitPermissions" :key="'orgUnitPermission' + permission.id">
                                <th scope="row" class="fw-normal">{{ permission.name }}</th>
                                <td>{{ permission.write ? 'Schreiben' : 'Lesen' }}</td>
                                <td class="text-end">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(permission, 'orgunit', 'update')" class="dropdown-item" href="#permissionEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(permission, 'orgunit', 'delete')" class="dropdown-item" href="#permissionDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.permission.noOrgUnits") }}</div>
                      </div>
                      <div class="tab-pane fade" id="kpis" role="tabpanel">
                        <div class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'kpi', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#permissionEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="kpiPermissions.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.permission.kpi") }}</th>
                                <th scope="col">{{ $t("views.permission.permission") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="permission in kpiPermissions" :key="'kpiPermission' + permission.id">
                                <th scope="row" class="fw-normal">{{ permission.name }}</th>
                                <td>{{ permission.write ? 'Schreiben' : 'Lesen' }}</td>
                                <td class="text-end">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(permission, 'kpi', 'update')" class="dropdown-item" href="#permissionEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(permission, 'kpi', 'delete')" class="dropdown-item" href="#permissionDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.permission.noKPIs") }}</div>
                      </div>
                      <div class="tab-pane fade" id="objectives" role="tabpanel">
                        <div class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'objective', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#permissionEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="objectivePermissions.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.permission.objective") }}</th>
                                <th scope="col">{{ $t("views.permission.permission") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="permission in objectivePermissions" :key="'objectivePermission' + permission.id">
                                <th scope="row" class="fw-normal">{{ permission.name }}</th>
                                <td>{{ permission.write ? 'Schreiben' : 'Lesen' }}</td>
                                <td class="text-end">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(permission, 'objective', 'update')" class="dropdown-item" href="#permissionEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(permission, 'objective', 'delete')" class="dropdown-item" href="#permissionDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.permission.noObjectives") }}</div>
                      </div>
                      <div class="tab-pane fade" id="projects" role="tabpanel">
                        <div class="row my-3">
                          <div class="col">
                            <button @click="openModal(false, 'project', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#permissionEditModal">+ {{ $t("general.add") }}</button>
                          </div>
                        </div>
                        <div v-if="projectPermissions.length">
                          <table class="table">
                            <thead>
                              <tr class="border-dark">
                                <th scope="col">{{ $t("views.permission.project") }}</th>
                                <th scope="col">{{ $t("views.permission.permission") }}</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="permission in projectPermissions" :key="'projectPermission' + permission.id">
                                <th scope="row" class="fw-normal">{{ permission.name }}</th>
                                <td>{{ permission.write ? 'Schreiben' : 'Lesen' }}</td>
                                <td class="text-end">
                                  <div class="dropdown">
                                    <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                      <svg class="bi" width="28" height="28" fill="currentColor">
                                        <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                      </svg>
                                    </a>
                                    <ul class="dropdown-menu">
                                      <li><a @click="openModal(permission, 'project', 'update')" class="dropdown-item" href="#permissionEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                      <li><a @click="openModal(permission, 'project', 'delete')" class="dropdown-item" href="#permissionDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div v-else class="mt-3 h6">{{ $t("views.permission.noProjects") }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="employees.length" class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p class="h5 mt-3">{{ $t("views.org.employee.helpChooseEmployee") }}</p>
        </div>
        <div v-else class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p class="h5 mt-3">{{ $t("views.permission.noUser") }}</p>
        </div>
      </div>
    </div>
    <div class="modal fade" data-bs-backdrop="static" id="duplicateModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.permission.duplicatePermissions") }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="row mb-3">
              <div class="col">
                <label for="employee" class="form-label">{{ $t("views.permission.selectEmployee") }}</label>
                <select v-model="form.permission.duplicateFrom.content" id="employee" class="form-select">
                  <option v-for="employee in employees" :key="'duplicate' + employee.id" :value="employee.id" :disabled="employee.id === selectedEmployee.id ? true : false">
                    {{ employee.lastName }}, {{ employee.firstName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div class="ms-auto">
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save('duplicate')" type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">{{ $t("views.permission.duplicate") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ref="permissionEditModal" class="modal fade" data-bs-backdrop="static" id="permissionEditModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.permission.permission") }} {{ vocabulary[mode] }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="row mb-3">
              <div v-if="elementType === 'orgunit'" class="col">
                <label for="orgUnit" class="form-label">{{ $t("views.permission.orgUnit") }}<span class="text-danger">*</span></label>
                <select v-model="form.permission.obj.content" id="orgUnit" class="form-select" :disabled="this.mode === 'update' ? true : false">
                  <option v-for="orgUnit in orgUnitList" :key="orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
                </select>
              </div>
              <div v-if="elementType === 'kpi'" class="col">
                <label for="kpi" class="form-label">{{ $t("views.permission.kpi") }}<span class="text-danger">*</span></label>
                <select v-model="form.permission.obj.content" id="kpi" class="form-select" :disabled="this.mode === 'update' ? true : false">
                  <option v-for="kpi in kpiList" :key="kpi.id" :value="kpi.id">{{ kpi.name }}</option>
                </select>
              </div>
              <div v-if="elementType === 'objective'" class="col">
                <label for="objectiveOrgUnit" class="form-label">{{ $t("views.permission.objective") }}<span class="text-danger">*</span></label>
                <select v-model="form.permission.obj.content" id="objectiveOrgUnit" class="form-select" :disabled="this.mode === 'update' ? true : false">
                  <option v-for="orgUnit in orgUnitList" :key="'objective' + orgUnit.id" :value="orgUnit.id">{{ orgUnit.name }}</option>
                </select>
              </div>
              <div v-if="elementType === 'project'" class="col">
                <label for="project" class="form-label">{{ $t("views.permission.project") }}<span class="text-danger">*</span></label>
                <select v-model="form.permission.obj.content" id="project" class="form-select" :disabled="this.mode === 'update' ? true : false">
                  <option v-for="project in projectList" :key="project.id" :value="project.id">{{ project.name }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="permission" class="form-label">{{ $t("views.permission.permission") }}<span class="text-danger">*</span></label>
                <select v-model="form.permission.write.content" id="permission" class="form-select">
                  <option value="false">{{ $t("views.permission.read") }}</option>
                  <option value="true">{{ $t("views.permission.write") }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="mode != 'add'" type="button" class="btn btn-sm btn-outline-danger" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#permissionDeleteModal">{{ $t("general.delete") }}</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save('default')" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" data-bs-backdrop="static" id="permissionDeleteModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.permission.deletePermission") }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="text-center my-3">
              <p>{{ $t("views.permission.confirmDeletePermission") }}</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="deletePermission()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">{{ $t("general.delete") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'SettingsManagePermission',
  data () {
    return {
      mode: 'add',
      elementType: '',
      form: {
        permission: {
          id: {
            content: '',
            validate: []
          },
          duplicateFrom: {
            content: '',
            validate: []
          },
          obj: {
            content: '',
            validate: []
          },
          write: {
            content: false,
            validate: []
          }
        }
      },
      vocabulary: {
        add: 'hinzufügen',
        update: 'bearbeiten',
        orgunit: 'ORG_UNIT',
        kpi: 'KPI',
        objective: 'OBJECTIVE',
        project: 'PROJECT'
      }
    }
  },
  components: {
    DocsInfoLink
  },
  computed: {
    employees () {
      return this.$store.state.employees
    },
    selectedEmployee () {
      return this.$store.state.selectedEmployee
    },
    orgUnitList () {
      if ('nodes' in this.$store.state.orgUnits) {
        return this.$store.state.orgUnitsList
      } else {
        return []
      }
    },
    orgUnitPermissions () {
      return this.$store.state.orgUnitPermissions
    },
    kpiList () {
      if ('nodes' in this.$store.state.kpis) {
        return this.$store.state.kpisList
      } else {
        return []
      }
    },
    kpiPermissions () {
      return this.$store.state.kpiPermissions
    },
    objectivePermissions () {
      return this.$store.state.objectivePermissions
    },
    projectList () {
      if ('nodes' in this.$store.state.projects) {
        return this.$store.state.projectsList
      } else {
        return []
      }
    },
    projectPermissions () {
      return this.$store.state.projectPermissions
    }
  },
  methods: {
    openModal (permission, elementType, mode) {
      if (permission) {
        this.form.permission.id.content = permission.id
        this.form.permission.obj.content = permission.obj
        this.form.permission.write.content = permission.write
      }
      this.elementType = elementType
      this.mode = mode
    },
    async getPermissions (employeeId) {
      await this.$store.dispatch('getPermissions', { model: 'orgunit', employee: employeeId, commit: 'SET_ORG_UNIT_PERMISSIONS' })
      await this.$store.dispatch('getPermissions', { model: 'kpi', employee: employeeId, commit: 'SET_KPI_PERMISSIONS' })
      await this.$store.dispatch('getPermissions', { model: 'objective', employee: employeeId, commit: 'SET_OBJECTIVE_PERMISSIONS' })
      await this.$store.dispatch('getPermissions', { model: 'project', employee: employeeId, commit: 'SET_PROJECT_PERMISSIONS' })
    },
    async selectEmployee (employeeId) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getEmployee', employeeId).then(() => {
        this.getPermissions(employeeId)
      })
      this.$store.commit('SET_LOADING_STATUS', false)
      window.scrollTo(0, 0)
    },
    async save (mode) {
      this.$store.commit('SET_LOADING_STATUS', true)

      let permissionData = {}
      if (mode === 'duplicate') {
        permissionData = { mode, employee: this.selectedEmployee.id, duplicateFrom: this.form.permission.duplicateFrom.content, commit: 'ALL' }
      } else {
        permissionData = { mode, model: this.elementType, employee: this.selectedEmployee.id, permissionId: this.form.permission.id.content, obj: this.form.permission.obj.content, write: this.form.permission.write.content, commit: 'SET_' + this.vocabulary[this.elementType] + '_PERMISSIONS' }
      }
      await this.$store.dispatch('addPermission', permissionData).then(() => {
        this.closePermissionEditModal()
        this.form.permission.id.content = this.form.permission.duplicateFrom.content = this.form.permission.obj.content = ''
        this.form.permission.write.content = false

        if (mode === 'duplicate') {
          this.getPermissions(this.selectedEmployee.id)
        }
      })

      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async deletePermission () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('deletePermission', { model: this.elementType, employee: this.selectedEmployee.id, commit: 'SET_' + this.vocabulary[this.elementType] + '_PERMISSIONS', permissionId: this.form.permission.id.content })
      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  beforeMount () {
    this.$store.state.selectedEmployee = {}
  },
  async created () {
    this.$store.commit('SET_LOADING_STATUS', true)
    await this.$store.dispatch('getEmployees', { mode: 'permissions' })
    if (Object.keys(this.selectedEmployee).length) {
      await this.$store.dispatch('getEmployee', this.selectedEmployee.id).then(() => {
        this.getPermissions(this.selectedEmployee.id)
      })
    }
    this.$store.commit('SET_LOADING_STATUS', false)
    await this.$store.dispatch('getOrgUnits', { validFrom: '2000-01-01', validTo: '2099-12-31' })
    await this.$store.dispatch('getKPIs', { validFrom: '2000-01-01', validTo: '2099-12-31' })
    await this.$store.dispatch('getObjectives', { validFrom: '2000-01-01', validTo: '2099-12-31' })
    await this.$store.dispatch('getProjects', { validFrom: '2000-01-01', validTo: '2099-12-31' })
  },
  watch: {
    mode (newMode) {
      if (newMode === 'add') {
        this.form.permission.id.content = this.form.permission.obj.content = ''
        this.form.permission.write.content = false
      }
    }
  },
  setup () {
    const permissionEditModal = ref(null)
    const closePermissionEditModal = () => Modal.getInstance(permissionEditModal.value)?.hide()
    return { permissionEditModal, closePermissionEditModal }
  }
}
</script>

<style lang="scss" scoped>
.employee {
  .cont-icon-employee span {
    font-size: 14px;
  }
  .employee-name:hover {
    font-weight: bold;
  }
}
</style>
