<template>
  <div class="row">
    <div v-if="Object.keys(selectedObjective).length" class="col-12">
      <div class="row mt-3">
        <div class="col">
          <a v-if="selectedObjective.write" @click="openModal(selectedObjective.id, 'objective', 'update')" class="btn btn-sm btn-outline-dark" href="#objectiveEditModal" data-bs-toggle="modal">&bull; {{ $t("views.objective.detail.btnEditObjective") }}</a>
        </div>
        <div class="col text-end">
          <DocsInfoLink color="dark" target="viewObjective"></DocsInfoLink>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="h3">{{ selectedObjective.name }}</div>
          <div class="row">
            <div class="col-12">
              <span class="badge bg-transparent text-dark fs-6 fw-normal ps-0 pe-1 pb-0">
                {{ orgUnitDisplay }}
                <span v-if="selectedObjective.mentor">&bull; {{ selectedObjective.mentor.name }}</span>
                &bull; {{ new Date(selectedObjective.validFrom).toLocaleDateString({dateStyle: 'short'}) }} - {{ new Date(selectedObjective.validTo).toLocaleDateString({dateStyle: 'short'}) }}
                <span v-if="selectedObjective.category">&bull; {{ $t("views.objective.edit.breakthrough") }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <div v-if="selectedObjective.criterion === 'KPI'" class="row">
            <div v-for="kpi in selectedObjective.kpis" :key="'kpi' + kpi.id" class="col-3 mb-3">
              <div v-if="!kpi.new">
                <div class="card">
                  <div class="card-header text-truncate">
                    <span class="fw-bold">{{ kpi.name }}</span>
                    <span> &bull; {{ unitDisplay(kpi, 'short') }}</span>
                  </div>
                  <div class="card-body text-center py-1">
                    <div class="mt-1">{{ kpi.displayedPeriod ? kpi.displayedPeriod : new Date().getFullYear() }}</div>
                    <KPIPeriodSingleChart :key="'objective-kpi-chart-single-' + kpi.id + curRecord(kpi).period" :chartId="'objectiveKPIChartSingle' + kpi.id + curRecord(kpi).period" :period="curRecord(kpi)" class="kpi-chart"></KPIPeriodSingleChart>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-6 my-auto">
                        <button class="btn btn-sm btn-outline-primary" :class="!kpi.records?.length ? 'disabled' : ''" data-bs-toggle="modal" :data-bs-target="'#kpiPeriodsModal' + kpi.id">{{ $t("views.objective.detail.viewKPI") }}</button>
                      </div>
                      <div class="col-4 offset-2 text-end my-auto">
                        <div class="progress position-relative border bg-dark border-dark">
                          <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ getProgressStatus(kpi) }}%</span>
                          <div class="progress-bar progress-bar-striped" :class="getProgressStatus(kpi) >= 100 ? 'bg-success' : 'bg-danger'" :style="'width: ' + getProgressStatus(kpi) + '%'" role="progressbar" :aria-valuenow="getProgressStatus(kpi)" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <KPIPeriodsModal :kpi="kpi"></KPIPeriodsModal>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12 mb-3">
              <KeyResultsTable :keyresults="selectedObjective.keyresults"></KeyResultsTable>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header fw-bold">{{ $t("views.objective.detail.headingCard") }}</div>
            <div class="card-body">
              <ul class="nav nav-tabs border-bottom" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark active" id="definition-tab" data-bs-toggle="tab" data-bs-target="#definition" type="button" role="tab" aria-controls="definition" aria-selected="false">{{ $t("views.objective.detail.tabDefinition") }}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews" type="button" role="tab" aria-controls="reviews" aria-selected="false">{{ $t("views.objective.detail.tabReviews") }} <span v-if="selectedObjective.reviews.length" class="badge bg-dark">{{ selectedObjective.reviews.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="measures-tab" data-bs-toggle="tab" data-bs-target="#measures" type="button" role="tab" aria-controls="measures" aria-selected="false">{{ $t("views.objective.detail.tabMeasures") }} <span v-if="selectedObjective.measures.length" class="badge bg-dark">{{ selectedObjective.measures.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="contribute-objectives-tab" data-bs-toggle="tab" data-bs-target="#contributeObjectives" type="button" role="tab" aria-controls="contributeObjectives" aria-selected="false">{{ $t("views.objective.detail.tabContributeObjectives") }} <span v-if="selectedObjective.contributeObjectives.length" class="badge bg-dark">{{ selectedObjective.contributeObjectives.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="conflicts-tab" data-bs-toggle="tab" data-bs-target="#conflicts" type="button" role="tab" aria-controls="conflicts" aria-selected="false">{{ $t("views.objective.detail.tabConflicts") }} <span v-if="selectedObjective.conflicts.length" class="badge bg-dark">{{ selectedObjective.conflicts.length }}</span></button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link text-dark" id="trials-tab" data-bs-toggle="tab" data-bs-target="#trials" type="button" role="tab" aria-controls="trials" aria-selected="false">{{ $t("views.objective.detail.tabTrials") }} <span v-if="selectedObjective.trials.length" class="badge bg-dark">{{ selectedObjective.trials.length }}</span></button>
                </li>
              </ul>
              <div class="tab-content mt-2">
                <div class="tab-pane fade show active" id="definition" role="tabpanel">
                  <div v-if="selectedObjective.description" v-html="selectedObjective.description" class="mt-3"></div>
                  <div v-else class="mt-3 h6">{{ $t("views.objective.detail.emptyDescription") }}</div>
                </div>
                <div class="tab-pane fade" id="reviews" role="tabpanel">
                  <div v-if="selectedObjective.write" class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'review', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectiveEditModal">+ {{ $t("general.add") }}</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.reviews.length">
                    <table class="table">
                      <thead>
                        <tr class="border-dark">
                          <th scope="col">{{ $t("views.objective.detail.reviewDate") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.reviewAssessment") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.reviewConversion") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.reviewPrediction") }}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="review in selectedObjective.reviews" :key="'review' + review.id">
                          <th scope="row">{{ new Date(review.date).toLocaleDateString({dateStyle: 'short'}) }}</th>
                          <td class="w-50">
                            <div v-html="review.assessment"></div>
                          </td>
                          <td>
                            <div class="progress position-relative border bg-dark border-dark w-50">
                              <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ 25 * review.conversion }}%</span>
                              <div class="progress-bar progress-bar-striped bg-primary" :style="'width: ' + (25 * review.conversion) + '%'" role="progressbar" :aria-valuenow="(25 * review.conversion)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td>
                            <span class="badge fw-normal" :class="review.prediction === 'Y' ? 'text-bg-success' : review.prediction === 'D' ? 'text-bg-warning' : 'text-bg-danger'">
                              {{ $t("views.objective.detail.review.prediction." + review.prediction.toLowerCase()) }}
                            </span>
                          </td>
                          <td v-if="selectedObjective.write" class="text-end">
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(review.id, 'review', 'update')" class="dropdown-item" href="#objectiveEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                <li><a @click="openModal(review.id, 'review', 'delete')" class="dropdown-item" href="#objectiveDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3 h6">{{ $t("views.objective.detail.emptyReviews") }}</div>
                </div>
                <div class="tab-pane fade" id="measures" role="tabpanel">
                  <div v-if="selectedObjective.write" class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'measure', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectiveEditModal">+ {{ $t("general.add") }}</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.measures.length">
                    <table class="table">
                      <thead>
                        <tr class="border-dark">
                          <th scope="col">{{ $t("views.objective.detail.measureTitle") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.measureResponsible") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.measureConversion") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.measureValidFrom") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.measureValidTo") }}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="measure in selectedObjective.measures" :key="'measure' + measure.id">
                          <th scope="row">{{ measure.title }}</th>
                          <td>{{ measure.responsible.name }}</td>
                          <td>
                            <div class="progress position-relative border bg-dark border-dark w-50">
                              <span class="progress-label position-absolute start-50 top-50 text-light fw-bold">{{ 25 * measure.conversion }}%</span>
                              <div class="progress-bar progress-bar-striped bg-primary" :style="'width: ' + (25 * measure.conversion) + '%'" role="progressbar" :aria-valuenow="(25 * measure.conversion)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </td>
                          <td>{{ new Date(measure.validFrom).toLocaleDateString({dateStyle: 'short'}) }}</td>
                          <td>{{ new Date(measure.validTo).toLocaleDateString({dateStyle: 'short'}) }}</td>
                          <td v-if="selectedObjective.write" class="text-end">
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(measure.id, 'measure', 'update')" class="dropdown-item" href="#objectiveEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                <li><a @click="openModal(measure.id, 'measure', 'delete')" class="dropdown-item" href="#objectiveDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3 h6">{{ $t("views.objective.detail.emptyMeasures") }}</div>
                </div>
                <div class="tab-pane fade" id="contributeObjectives" role="tabpanel">
                  <div v-if="selectedObjective.contributeObjectives.length">
                    <div class="row mt-3">
                      <div class="col-3">
                        <ul class="list-group">
                          <li class="list-group-item list-group-item-success fw-bold border-2">{{ $t("views.objective.detail.review.prediction.y") }}</li>
                          <li v-for="contributeObjective in selectedObjective.contributeObjectives.filter(objective => objective.status === 'Y')"
                              :key="'contributeObjective' + contributeObjective.id" @click="selectObjective(contributeObjective)" class="list-group-item list-group-item-child text-truncate">
                            {{ contributeObjective.name }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-3">
                        <ul class="list-group">
                          <li class="list-group-item list-group-item-warning fw-bold border-2">{{ $t("views.objective.detail.review.prediction.d") }}</li>
                          <li v-for="contributeObjective in selectedObjective.contributeObjectives.filter(objective => objective.status === 'D')"
                              :key="'contributeObjective' + contributeObjective.id" @click="selectObjective(contributeObjective)" class="list-group-item list-group-item-child text-truncate">
                            {{ contributeObjective.name }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-3">
                        <ul class="list-group">
                          <li class="list-group-item list-group-item-danger fw-bold border-2">{{ $t("views.objective.detail.review.prediction.n") }}</li>
                          <li v-for="contributeObjective in selectedObjective.contributeObjectives.filter(objective => objective.status === 'N')"
                              :key="'contributeObjective' + contributeObjective.id" @click="selectObjective(contributeObjective)" class="list-group-item list-group-item-child text-truncate">
                            {{ contributeObjective.name }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-3">
                        <ul class="list-group">
                          <li class="list-group-item fw-bold border-2">{{ $t("views.objective.detail.contributeObjectivesWithoutReviews") }}</li>
                          <li v-for="contributeObjective in selectedObjective.contributeObjectives.filter(objective => objective.status === 'U')"
                              :key="'contributeObjective' + contributeObjective.id" @click="selectObjective(contributeObjective)" class="list-group-item list-group-item-child text-truncate">
                            {{ contributeObjective.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-3 h6">{{ $t("views.objective.detail.emptyContributeObjectives") }}</div>
                </div>
                <div class="tab-pane fade" id="conflicts" role="tabpanel">
                  <div v-if="selectedObjective.write" class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'conflict', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectiveEditModal">+ {{ $t("general.add") }}</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.conflicts.length">
                    <table class="table">
                      <thead>
                        <tr class="border-dark">
                          <th scope="col">{{ $t("views.objective.detail.conflictDescription") }}</th>
                          <th scope="col">{{ $t("views.objective.detail.conflictStatus") }}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="conflict in selectedObjective.conflicts" :key="'conflict' + conflict.id">
                          <th v-html="conflict.description" scope="row"></th>
                          <td>
                            <svg class="bi" width="20" height="20" fill="currentColor">
                              <use :class="conflict.status ? 'text-success' : 'text-danger'" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                            </svg>
                          </td>
                          <td v-if="selectedObjective.write" class="text-end">
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="openModal(conflict.id, 'conflict', 'update')" class="dropdown-item" href="#objectiveEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                <li><a @click="openModal(conflict.id, 'conflict', 'delete')" class="dropdown-item" href="#objectiveDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3 h6">{{ $t("views.objective.detail.emptyConflicts") }}</div>
                </div>
                <div class="tab-pane fade" id="trials" role="tabpanel">
                  <div v-if="selectedObjective.write" class="row my-3">
                    <div class="col">
                      <button @click="openModal(false, 'trial', 'add')" type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#objectiveEditModal">+ {{ $t("general.add") }}</button>
                    </div>
                  </div>
                  <div v-if="selectedObjective.trials.length">
                    <table class="table">
                      <thead>
                        <tr class="border-dark">
                          <th scope="col">{{ $t("views.objective.detail.trialDescription") }}</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="trial in selectedObjective.trials" :key="'trial' + trial.id">
                          <th scope="row">{{ trial.description }}</th>
                          <td v-if="selectedObjective.write" class="text-end">
                            <div class="dropdown">
                              <a href="#" class="ms-2 text-dark" data-bs-toggle="dropdown">
                                <svg class="bi" width="28" height="28" fill="currentColor">
                                  <use xlink:href="@/assets/icons/icons.svg#three-dots"/>
                                </svg>
                              </a>
                              <ul class="dropdown-menu">
                                <li><a @click="objectiveFromTrial(trial)" class="dropdown-item" href="#">{{ $t("views.objective.detail.trialGenerateObjective") }}</a></li>
                                <li><a @click="openModal(trial.id, 'trial', 'update')" class="dropdown-item" href="#objectiveEditModal" data-bs-toggle="modal">{{ $t("general.update") }}</a></li>
                                <li><a @click="openModal(trial.id, 'trial', 'delete')" class="dropdown-item" href="#objectiveDeleteModal" data-bs-toggle="modal">{{ $t("general.delete") }}</a></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="mt-3 h6">{{ $t("views.objective.detail.emptyTrials") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center mt-5">
      <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
      <p v-if="'nodes' in objectives && objectives.nodes.length" class="h5 mt-3">{{ $t("views.objective.structure.helpChooseObjective") }}</p>
      <p v-else class="h5 mt-3">{{ $t("views.objective.structure.helpCreateObjective") }}</p>
    </div>
    <ObjectiveEditModal></ObjectiveEditModal>
    <ObjectiveDeleteModal></ObjectiveDeleteModal>
  </div>
</template>

<script>
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import ObjectiveEditModal from './ObjectiveEditModal.vue'
import ObjectiveDeleteModal from './ObjectiveDeleteModal.vue'
import KeyResultsTable from './KeyResultsTable.vue'
import KPIPeriodSingleChart from '../charts/KPIPeriodSingle.vue'
import KPIPeriodsModal from '../monitoring/KPIPeriodsModal.vue'

export default {
  name: 'ObjectivesManageObjectiveDetail',
  data () {
    return {
      objectiveModalElementId: false,
      objectiveModalKeyResultId: false,
      objectiveModalElementType: '',
      objectiveModalMode: '',
      kpiChart: ''
    }
  },
  components: {
    DocsInfoLink,
    ObjectiveEditModal,
    ObjectiveDeleteModal,
    KeyResultsTable,
    KPIPeriodSingleChart,
    KPIPeriodsModal
  },
  computed: {
    objectives () {
      return this.$parent.objectives
    },
    newObjective () {
      return this.$store.state.newObjective
    },
    selectedObjective () {
      return this.$store.state.selectedObjective
    },
    orgUnitsList () {
      return this.$store.state.orgUnitsList
    },
    contributeObjectivesPos () {
      return this.selectedObjective.contributeObjectives.filter(o => o.status < 6)
    },
    contributeObjectivesNeu () {
      return this.selectedObjective.contributeObjectives.filter(o => o.status > 5 && o.status < 11)
    },
    contributeObjectivesNeg () {
      return this.selectedObjective.contributeObjectives.filter(o => o.status > 10)
    },
    orgUnitDisplay () {
      const orgUnit = this.orgUnitsList.find(orgUnit => {
        return orgUnit.id === this.selectedObjective.orgUnit
      })
      if (orgUnit) {
        return orgUnit.name.replace(/- /gm, '').trim()
      } else {
        return 'n/a'
      }
    }
  },
  methods: {
    openModal (id, elementType, mode) {
      if (id) {
        this.objectiveModalElementId = id
      }
      this.objectiveModalElementType = elementType
      this.objectiveModalMode = mode
    },
    async selectObjective (objective) {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getObjective', objective.id).then(() => {
        this.objectiveModalElementId = objective.id
      })
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    curRecord (kpi) {
      let curRecord = new Date().getFullYear()
      if (this.selectedObjective.validTo.substring(0, 4) < curRecord) {
        curRecord = this.selectedObjective.validTo.substring(0, 4)
      }

      const foundRecord = kpi.records?.find(record => {
        return parseInt(record.period) === parseInt(curRecord)
      })

      if (foundRecord) {
        const prevYearRecord = kpi.records?.find(record => {
          return parseInt(record.period) === parseInt(curRecord - 1)
        })
        if (prevYearRecord) {
          foundRecord.actualPrev = prevYearRecord.actual
        }
        kpi.displayedPeriod = foundRecord.period
        return foundRecord
      } else {
        return {}
      }
    },
    unitDisplay (kpi, displayMode) {
      const unit = this.$store.state.kpiUnits.find(unit => {
        return unit.id === kpi.unit
      })
      if (unit) {
        if (displayMode === 'short') {
          return unit.name
        } else {
          return unit.name + ' (' + unit.unit + ')'
        }
      } else {
        return 'n/a'
      }
    },
    getProgressStatus (kpi) {
      const record = this.curRecord(kpi)
      if (Object.keys(record).length) {
        let progressStatus = 0
        if (record.forecast !== '' && record.objective !== '') {
          progressStatus = Math.round((record.forecast / record.objective) * 100)
        }
        return progressStatus
      } else {
        return 0
      }
    },
    objectiveFromTrial (trial) {
      this.newObjective.name = trial.description
      this.newObjective.orgUnit = this.selectedObjective.orgUnit
      this.newObjective.parentId = this.selectedObjective.id
      this.$store.dispatch('updateObjectivesContentView', 'add')
    }
  }
}
</script>

<style lang="scss" scoped>
.kpi-chart {
  max-height: 200px;
}
.progress-label {
  transform: translate(-50%, -50%);
}
th[scope='row'] {
  font-weight: normal;
}
th, td {
  padding-top: 11px !important;
}
.list-group-item-child {
  cursor: pointer;
}
.list-group-item-child:hover {
  font-weight: bold;
}
.tab-content .progress {
  margin-top: 6px;
}
</style>
