<template>
  <div class="org-modal">
    <div ref="orgUnitEditModal" class="modal fade" data-bs-backdrop="static" id="orgUnitEditModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">
              {{ vocabulary[elementType] }} {{ vocabulary[mode] }}
              <DocsInfoLink color="light" target="createOrg"></DocsInfoLink>
            </div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div v-if="elementType === 'orgUnit'" class="form-org-unit">
              <div class="row mb-3">
                <div class="col">
                  <label for="orgParent" class="form-label">{{ $t("views.org.edit.parentOrgUnit") }}</label>
                  <select v-model="form.orgUnit.parentId.content" class="form-select" id="orgParent">
                    <option value="" :disabled="!isAdmin">{{ $t("views.org.edit.noParentOrgUnit") }}</option>
                    <option v-for="orgUnit in orgUnitsList" :key="orgUnit.id" :value="orgUnit.id" :disabled="mode === 'update' && orgUnit.id === selectedOrgUnit.id ? true : false">
                      {{ orgUnit.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="orgUnitName" class="form-label">{{ $t("views.org.edit.name") }}<span class="text-danger">*</span></label>
                  <input v-model="form.orgUnit.name.content" id="orgUnitName" :class="form.orgUnit.name.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.orgUnit.name.error }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="orgUnitDescription" class="form-label">{{ $t("views.org.edit.description") }}</label>
                  <RTEditor ref="rtEditor-description" v-model="form.orgUnit.description.content" contentType="html" id="orgUnitDescription"></RTEditor>
                  <div class="quill-invalid-feedback">{{ form.orgUnit.description.error }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="orgUnitMission" class="form-label">{{ $t("views.org.edit.mission") }}</label>
                  <RTEditor ref="rtEditor-mission" v-model="form.orgUnit.mission.content" contentType="html" id="orgUnitMission"></RTEditor>
                  <div class="quill-invalid-feedback">{{ form.orgUnit.mission.error }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="orgUnitVision" class="form-label">{{ $t("views.org.edit.vision") }}</label>
                  <RTEditor ref="rtEditor-vision" v-model="form.orgUnit.vision.content" contentType="html" id="orgUnitVision"></RTEditor>
                  <div class="quill-invalid-feedback">{{ form.orgUnit.vision.error }}</div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <div class="form-check form-switch">
                    <input @change="toggleOrgValidCheck(!validCheck)" type="checkbox" role="switch" id="orgUnitValidCheck" class="form-check-input" :checked="validCheck">
                    <label for="orgUnitValidCheck" class="form-check-label">{{ $t("views.org.edit.validCheck") }}</label>
                  </div>
                </div>
              </div>
              <div v-if="validCheck" class="row mb-3">
                <div class="col-6">
                  <label for="orgUnitValidFrom" class="form-label">{{ $t("views.org.edit.validFrom") }}</label>
                  <input v-model="form.orgUnit.validFrom.content" type="date" id="orgUnitValidFrom" :class="form.orgUnit.validFrom.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.orgUnit.validFrom.error }}</div>
                </div>
                <div class="col-6">
                  <label for="orgUnitValidTo" class="form-label">{{ $t("views.org.edit.validTo") }}</label>
                  <input v-model="form.orgUnit.validTo.content" type="date" id="orgUnitValidTo" :class="form.orgUnit.validTo.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.orgUnit.validTo.error }}</div>
                </div>
              </div>
            </div>
            <div v-if="elementType === 'employee'" class="form-org-unit">
              <div class="row mb-3">
                <div class="col">
                  <label for="existingEmployees" class="form-label">{{ $t("views.org.edit.selectEmployee") }}<span class="text-danger">*</span></label>
                  <select v-model="form.employee.id.content" class="form-select" id="existingEmployees">
                    <option v-for="person in employees" :key="person.id" :value="person.id">
                      {{ person.lastName }}, {{ person.firstName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="mode === 'update'" @click="updateModalMode()" type="button" class="btn btn-sm btn-outline-danger" :class="selectedOrgUnit.hasNodes || selectedOrgUnit.employees.length ? 'disabled' : ''" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#orgUnitDeleteModal">{{ $t("general.delete") }}</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import RTEditor from '../RTEditor.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'OrgUnitEditModal',
  data () {
    return {
      vocabulary: {
        orgUnit: 'Org-Einheit',
        employee: 'Mitarbeiter',
        add: 'hinzufügen',
        update: 'bearbeiten'
      },
      form: {
        orgUnit: {
          parentId: {
            content: '',
            error: '',
            validate: []
          },
          name: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          description: {
            content: '',
            error: '',
            validate: [],
            rtEditor: true
          },
          mission: {
            content: '',
            error: '',
            validate: [],
            rtEditor: true
          },
          vision: {
            content: '',
            error: '',
            validate: [],
            rtEditor: true
          },
          validFrom: {
            content: '',
            error: '',
            validate: []
          },
          validTo: {
            content: '',
            error: '',
            validate: []
          }
        },
        employee: {
          id: {
            content: '',
            error: '',
            validate: []
          }
        }
      },
      selectedElement: ''
    }
  },
  components: {
    DocsInfoLink,
    RTEditor
  },
  computed: {
    elementId () {
      return this.$parent.orgUnitModalElementId
    },
    elementType () {
      return this.$parent.orgUnitModalElementType
    },
    mode () {
      return this.$parent.orgUnitModalMode
    },
    orgUnitsList () {
      return this.$store.state.orgUnitsList
    },
    employees () {
      return this.$store.state.employees
    },
    selectedOrgUnit () {
      return this.$store.state.selectedOrgUnit
    },
    validCheck () {
      if (this.form.orgUnit.validFrom.content) {
        return true
      }
      return false
    },
    isAdmin () {
      return this.$store.state.user.role === 10387
    }
  },
  methods: {
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      Object.keys(this.form[this.elementType]).forEach(field => {
        if (this.form[this.elementType][field].rtEditor) {
          this.form[this.elementType][field].content = this.$refs['rtEditor-' + field].getContent()
        }

        this.form[this.elementType][field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form[this.elementType], field, validation)
          if (!validationResult.status) {
            this.form[this.elementType][field].error = validationResult.content
            valid++
          }
        })
      })

      if (valid === 0) {
        let elementData = Object.assign(
          ...Object.entries(this.form[this.elementType]).map(([key, value]) => ({ [key]: value.content }))
        )
        if (this.mode === 'update') {
          elementData.id = this.elementId
        }
        if (this.elementType !== 'orgUnit') {
          elementData = { orgUnitId: this.selectedOrgUnit.id, elementType: (this.elementType + 's'), element: elementData }
        }

        const dispatchFunc = this.elementType === 'orgUnit' ? (this.mode + 'OrgUnit') : (this.mode + 'OrgUnitElement')
        await this.$store.dispatch(dispatchFunc, elementData).then(() => {
          this.closeModal()
          Object.keys(this.form[this.elementType]).forEach(field => {
            if (this.mode === 'add') {
              this.form[this.elementType][field].content = ''
            }
            this.form[this.elementType][field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)

      await this.$store.dispatch('getOrgUnits', { validFrom: this.$parent.filterValidFrom, validTo: this.$parent.filterValidTo })
    },
    toggleOrgValidCheck (validCheck) {
      if (validCheck) {
        if (this.mode === 'update' && this.selectedOrgUnit.validFrom && this.selectedOrgUnit.validTo) {
          [this.form.orgUnit.validFrom.content, this.form.orgUnit.validTo.content] = [this.selectedOrgUnit.validFrom, this.selectedOrgUnit.validTo]
        } else {
          this.form.orgUnit.validFrom.content = '2000-01-01'
          this.form.orgUnit.validTo.content = '2099-12-31'
        }
      } else {
        this.form.orgUnit.validFrom.content = this.form.orgUnit.validTo.content = ''
      }
    },
    updateModalContent () {
      this.form.orgUnit.parentId.content = ''
      Object.keys(this.form[this.elementType]).forEach(field => {
        let selectedElement = this.selectedOrgUnit
        if (this.elementType !== 'orgUnit') {
          const elementTypePlural = this.elementType + 's'
          selectedElement = this.selectedOrgUnit[elementTypePlural].find(element => {
            return element.id === this.$parent.orgUnitModalElementId
          })
          this.$parent.selectedElement = selectedElement
        }
        if (field in selectedElement) {
          this.form[this.elementType][field].content = selectedElement[field]
          this.form[this.elementType][field].error = ''
        }
      })
    },
    updateModalMode () {
      if (this.elementType === 'orgUnit') {
        this.$parent.orgUnitModalMode = 'delete'
      }
    }
  },
  watch: {
    elementId: function () {
      this.updateModalContent()
    },
    mode (newMode) {
      if (newMode === 'add') {
        Object.keys(this.form[this.elementType]).forEach(field => {
          this.form[this.elementType][field].content = this.form[this.elementType][field].error = ''
        })
      } else if (newMode === 'update') {
        this.updateModalContent()
      }
    }
  },
  setup () {
    const orgUnitEditModal = ref(null)
    const closeModal = () => Modal.getInstance(orgUnitEditModal.value)?.hide()
    return { orgUnitEditModal, closeModal }
  }
}
</script>
