<template>
  <div>
    <div>
      <div class="container my-5">
        <div class="row justify-content-center">
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <div class="h4">
                  {{ $t("views.kpi.detail.headingUnits") }}
                  <DocsInfoLink color="dark" target="manageKPIUnits"></DocsInfoLink>
                </div>
              </div>
              <div class="col-6 text-end">
                <button @click="openModal('add')" type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#kpiUnitEditModal">+ {{ $t("general.add") }}</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="kpiUnits.length" class="row justify-content-center">
          <div class="col-6">
            <div class="bg-white rounded-2 p-2">
              <table class="table">
                <thead>
                  <tr class="border-dark">
                    <th scope="col">{{ $t("views.kpi.edit.label") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.name") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.decimals") }}</th>
                    <th scope="col">{{ $t("views.kpi.edit.usage") }}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="kpiUnit in kpiUnits" :key="kpiUnit.id">
                    <th role="button" scope="row">
                      <a @click="openModal('update', kpiUnit)" href="#" data-bs-toggle="modal" data-bs-target="#kpiUnitEditModal">{{ kpiUnit.unit }}</a>
                    </th>
                    <td>{{ kpiUnit.name }}</td>
                    <td>{{ kpiUnit.decimals }}</td>
                    <td>
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use v-if="kpiUnit.used" class="text-success" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                        <use v-else class="text-danger" xlink:href="@/assets/icons/icons.svg#circle-fill"/>
                      </svg>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-else-if="!loadingStatus" class="text-center mt-5">
          <img src="@/assets/img/no_data.svg" class="img-fluid" width="250">
          <p class="h5 mt-3">{{ $t("views.kpi.detail.noUnitsAdded") }}</p>
        </div>
      </div>
    </div>
    <div ref="kpiUnitEditModal" class="modal fade" data-bs-backdrop="static" id="kpiUnitEditModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.kpi.detail.kpiUnit") }} {{ vocabulary[mode] }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="form-kpi-unit">
              <div class="row">
                <div class="col-6">
                  <label for="kpiUnitUnit" class="form-label small mb-0">{{ $t("views.kpi.edit.label") }}<span class="text-danger">*</span></label>
                  <input v-model="form.kpiUnit.unit.content" id="kpiUnitUnit" :class="form.kpiUnit.unit.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.kpiUnit.unit.error }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label for="kpiUnitName" class="form-label small mt-3 mb-0">{{ $t("views.kpi.edit.name") }}<span class="text-danger">*</span></label>
                  <input v-model="form.kpiUnit.name.content" id="kpiUnitName" :class="form.kpiUnit.name.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.kpiUnit.name.error }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <label for="kpiUnitDecimals" class="form-label small mt-3 mb-0">{{ $t("views.kpi.edit.decimals") }}</label>
                  <input v-model="form.kpiUnit.decimals.content" type="number" min="0" max="3" id="kpiUnitDecimals" :class="form.kpiUnit.decimals.error === '' ? 'form-control' : 'form-control is-invalid'">
                  <div class="invalid-feedback">{{ form.kpiUnit.decimals.error }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light justify-content-between">
            <div>
              <button v-if="mode === 'update'" type="button" class="btn btn-sm btn-outline-danger" :class="isSelectedKPIUsed ? 'disabled' : ''" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#kpiUnitDeleteModal">{{ $t("general.delete") }}</button>
            </div>
            <div>
              <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
              <button @click="save()" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" data-bs-backdrop="static" id="kpiUnitDeleteModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ $t("views.kpi.detail.headingDeleteUnit") }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="text-center my-3">
              <p class="fw-bold">{{ form.kpiUnit.unit.content }} &bull; {{ form.kpiUnit.name.content }}</p>
              <p>{{ $t("views.kpi.detail.confirmDeleteUnit") }}</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="deleteKPIUnit()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">{{ $t("general.delete") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import DocsInfoLink from '../../components/DocsInfoLink.vue'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'MonitoringManageKPIUnit',
  data () {
    return {
      vocabulary: {
        add: 'hinzufügen',
        update: 'bearbeiten'
      },
      mode: 'add',
      kpiUnitId: false,
      isSelectedKPIUsed: false,
      form: {
        kpiUnit: {
          unit: {
            content: '',
            error: '',
            maxLength: 10,
            validate: ['length', 'empty']
          },
          name: {
            content: '',
            error: '',
            maxLength: 255,
            validate: ['length', 'empty']
          },
          decimals: {
            content: '',
            error: '',
            validate: []
          }
        }
      }
    }
  },
  components: {
    DocsInfoLink
  },
  computed: {
    kpiUnits () {
      return this.$store.state.kpiUnits
    },
    loadingStatus () {
      return this.$store.state.loadingStatus
    }
  },
  methods: {
    async getKPIUnits () {
      this.$store.commit('SET_LOADING_STATUS', true)
      await this.$store.dispatch('getKPIUnits', this.selectedYear)
      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async save () {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0

      Object.keys(this.form.kpiUnit).forEach(field => {
        // Decimals validation
        if (field === 'decimals' && (this.form.kpiUnit[field].content < 0 || this.form.kpiUnit[field].content > 3)) {
          this.form.kpiUnit[field].error = 'Bitte zwischen 0 und 3 Dezimalstellen wählen.'
          valid++
        }
        // General validation
        this.form.kpiUnit[field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.form.kpiUnit, field, validation)
          if (!validationResult.status) {
            this.form.kpiUnit[field].error = validationResult.content
            valid++
          }
        })
      })

      const duplicateKPIUnit = this.kpiUnits.find(kpiUnit => kpiUnit.unit === this.form.kpiUnit.unit.content)
      if (duplicateKPIUnit && duplicateKPIUnit.id !== this.kpiUnitId) {
        this.form.kpiUnit.unit.error = 'Diese Einheit existiert bereits.'
        valid++
      }

      if (valid === 0) {
        const kpiUnitData = {
          id: this.kpiUnitId,
          unit: this.form.kpiUnit.unit.content,
          name: this.form.kpiUnit.name.content,
          decimals: this.form.kpiUnit.decimals.content
        }
        const dispatchFunc = this.mode === 'add' ? 'addKPIUnit' : 'updateKPIUnit'
        await this.$store.dispatch(dispatchFunc, kpiUnitData).then(() => {
          this.closeModal()
          Object.keys(this.form.kpiUnit).forEach(field => {
            if (this.mode === 'add') {
              this.form.kpiUnit[field].content = ''
            }
            this.form.kpiUnit[field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)
    },
    async deleteKPIUnit () {
      await this.$store.dispatch('deleteKPIUnit', this.kpiUnitId)
    },
    openModal (mode, kpiUnit) {
      this.mode = mode
      if (mode === 'add') {
        this.kpiUnitId = false
        this.form.kpiUnit.unit.content = this.form.kpiUnit.unit.error = ''
        this.form.kpiUnit.name.content = this.form.kpiUnit.name.error = ''
        this.form.kpiUnit.decimals.content = this.form.kpiUnit.decimals.error = ''
        this.isSelectedKPIUsed = false
      } else {
        this.kpiUnitId = kpiUnit.id
        this.form.kpiUnit.unit.content = kpiUnit.unit
        this.form.kpiUnit.unit.error = ''
        this.form.kpiUnit.name.content = kpiUnit.name
        this.form.kpiUnit.name.error = ''
        this.form.kpiUnit.decimals.content = kpiUnit.decimals
        this.form.kpiUnit.decimals.error = ''
        this.isSelectedKPIUsed = kpiUnit.used
      }
    }
  },
  async created () {
    this.getKPIUnits()
  },
  setup () {
    const kpiUnitEditModal = ref(null)
    const closeModal = () => Modal.getInstance(kpiUnitEditModal.value)?.hide()
    return { kpiUnitEditModal, closeModal }
  }
}
</script>

<style scoped>
table tbody th {
  font-weight: normal;
}
</style>
