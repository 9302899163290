<template>
  <div class="account-modal">
    <div ref="accountEditModal" class="modal fade" data-bs-backdrop="static" id="accountEditModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ vocabulary[elementType] }} ändern</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div v-if="elementType === 'name'">
              <div class="mb-3">
                <label for="inputNewFirstName" class="form-label">{{ $t("views.account.edit.firstName") }}<span class="text-danger">*</span></label>
                <input v-model="newUserData.name.newFirstName.content" type="text" :class="newUserData.name.newFirstName.error === '' ? 'form-control' : 'form-control is-invalid'"
                  id="inputNewFirstName">
                <div class="invalid-feedback">{{ newUserData.name.newFirstName.error }}</div>
              </div>
              <div class="mb-3">
                <label for="inputNewLastName" class="form-label">{{ $t("views.account.edit.lastName") }}<span class="text-danger">*</span></label>
                <input v-model="newUserData.name.newLastName.content" type="text" :class="newUserData.name.newLastName.error === '' ? 'form-control' : 'form-control is-invalid'"
                  id="inputNewLastName">
                <div class="invalid-feedback">{{ newUserData.name.newLastName.error }}</div>
              </div>
            </div>
            <div v-if="elementType === 'email'">
              <div class="mb-3">
                <label for="inputNewEmail" class="form-label">{{ $t("views.account.edit.email") }}<span class="text-danger">*</span></label>
                <input v-model="newUserData.email.newEmail.content" type="email" :class="newUserData.email.newEmail.error === '' ? 'form-control' : 'form-control is-invalid'"
                  id="inputNewEmail">
                <div class="invalid-feedback">{{ newUserData.email.newEmail.error }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="saveAccount(elementType)" type="button" class="btn btn-sm btn-primary">{{ $t("general.save") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as validate from '../../composables/validation'
import { Modal } from 'bootstrap'
import { ref } from 'vue'

export default {
  name: 'AccountEditModal',
  data () {
    return {
      element: {},
      vocabulary: {
        name: 'Name',
        email: 'E-Mail',
        password: 'Passwort'
      },
      newUserData: {
        name: {
          newFirstName: {
            content: '',
            error: '',
            maxLength: 150,
            validate: ['length', 'empty']
          },
          newLastName: {
            content: '',
            error: '',
            maxLength: 150,
            validate: ['length', 'empty']
          }
        },
        email: {
          newEmail: {
            content: '',
            error: '',
            validate: ['email', 'empty']
          }
        }
      }
    }
  },
  computed: {
    user () {
      return this.$parent.user
    },
    elementType () {
      return this.$parent.userModalElementType
    }
  },
  methods: {
    async saveAccount (elementType) {
      this.$store.commit('SET_LOADING_STATUS', true)

      let valid = 0
      Object.keys(this.newUserData[elementType]).forEach(field => {
        this.newUserData[elementType][field].validate.forEach(validation => {
          const validationResult = validate.useValidation(this.newUserData[elementType], field, validation)
          if (!validationResult.status) {
            this.newUserData[elementType][field].error = validationResult.content
            valid++
          }
        })
      })
      if (valid === 0) {
        const elementData = Object.assign(
          ...Object.entries(this.newUserData[elementType]).map(([key, value]) => ({ [key]: value.content }))
        )

        await this.$store.dispatch('updateUserDetails', elementData).then(() => {
          this.closeModal()
          Object.keys(this.newUserData[elementType]).forEach(field => {
            this.newUserData[elementType][field].error = ''
          })
        })
      }

      this.$store.commit('SET_LOADING_STATUS', false)
    }
  },
  created () {
    this.newUserData.name.newFirstName.content = this.$store.state.user.firstName
    this.newUserData.name.newLastName.content = this.$store.state.user.lastName
    this.newUserData.email.newEmail.content = this.$store.state.user.email
  },
  setup () {
    const accountEditModal = ref(null)
    const closeModal = () => Modal.getInstance(accountEditModal.value)?.hide()
    return { accountEditModal, closeModal }
  }
}
</script>
