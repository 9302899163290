<template>
  <div class="kpi-modal">
    <div class="modal fade" data-bs-backdrop="static" :id="'kpiPeriodsModal' + kpi.id" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ kpi.name }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="row">
              <div class="col text-center">
                <div>{{ $t("views.kpi.edit.unit") }}: {{ unitDisplay }}</div>
              </div>
            </div>
            <div class="row justify-content-center mb-4">
              <div class="col-3">
                <label for="filterTimeDim" class="form-label small mb-0">{{ $t("views.kpi.periods.timeDim") }}:</label>
                <select @change="updateFilterFromTo()" v-model="filterTimeDim" id="filterTimeDim" class="form-select form-select-sm">
                  <option v-if="kpi.timeDimInput === 'M'" value="M">{{ $t("general.month") }}</option>
                  <option v-if="kpi.timeDimInput !== 'Y'" value="Q">{{ $t("general.quarter") }}</option>
                  <option value="Y">{{ $t("general.year") }}</option>
                </select>
              </div>
              <div class="col-3">
                <label for="filterFrom" class="form-label small mb-0">{{ $t("views.kpi.periods.from") }}:</label>
                <select v-model="filterFrom" id="filterFrom" class="form-select form-select-sm">
                  <option v-for="option in filterOptions" :key="'filterFrom' + option" :value="option">{{ formatPeriod(option) }}</option>
                </select>
              </div>
              <div class="col-3">
                <label for="filterTo" class="form-label small mb-0">{{ $t("views.kpi.periods.to") }}:</label>
                <select v-model="filterTo" id="filterTo" class="form-select form-select-sm">
                  <option v-for="option in filterOptions" :key="'filterTo' + option" :value="option">{{ formatPeriod(option) }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <KPIPeriodMultiChart v-if="kpi.records" :key="'kpi-periods-multi' + kpiKeyInt + kpi.id" :chartId="'chartMulti' + kpiKeyInt + kpi.id" :periods="filteredPeriods" class="kpi-chart"></KPIPeriodMultiChart>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="table-kpi-data mt-3">
                  <table class="table table-bordered table-striped mb-0">
                    <thead>
                      <tr>
                        <th scope="col">{{ $t("views.kpi.periods.period") }}</th>
                        <th scope="col">{{ $t("views.kpi.edit.actual") }}</th>
                        <th scope="col">{{ $t("views.kpi.edit.plan") }}</th>
                        <th scope="col">{{ $t("views.kpi.edit.objective") }}</th>
                        <th scope="col">{{ $t("views.kpi.edit.forecast") }}</th>
                        <th scope="col">{{ $t("views.kpi.edit.benchmark") }}</th>
                        <th scope="col">
                          <select v-model="selectedDeviation" class="form-select select-th">
                            <option value="actualPlan">{{ $t("views.kpi.periods.devActualPlan") }}</option>
                            <option value="actualObjective">{{ $t("views.kpi.periods.devActualObjective") }}</option>
                            <option value="forecastPlan">{{ $t("views.kpi.periods.devForecastPlan") }}</option>
                            <option value="forecastActual">{{ $t("views.kpi.periods.devForecastActual") }}</option>
                          </select>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="period in filteredPeriods" :key="kpi.id + period" class="text-end">
                        <th scope="row">{{ formatPeriod(period.period) }}</th>
                        <td>{{ period.actual ? period.actual.toLocaleString('de-DE') : '-' }}</td>
                        <td>{{ period.plan ? period.plan.toLocaleString('de-DE') : '-' }}</td>
                        <td>{{ period.objective ? period.objective.toLocaleString('de-DE') : '-' }}</td>
                        <td>{{ period.forecast ? period.forecast.toLocaleString('de-DE') : '-' }}</td>
                        <td>{{ period.benchmark ? period.benchmark.toLocaleString('de-DE') : '-' }}</td>
                        <td :class="period.devActualPlan?.includes('+') ? 'text-success' : 'text-danger'">{{ period.devActualPlan ? period.devActualPlan.toLocaleString('de-DE') : '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary me-2" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as periodUtils from '../../composables/period'
import KPIPeriodMultiChart from '../charts/KPIPeriodMulti.vue'

export default {
  name: 'KPIPeriodsModal',
  props: ['kpi'],
  data () {
    return {
      kpiKeyInt: 0,
      filterTimeDim: this.kpi.timeDimDisplay,
      filterFrom: '',
      filterTo: '',
      filterOptions: '',
      selectedDeviation: 'actualPlan'
    }
  },
  components: {
    KPIPeriodMultiChart
  },
  computed: {
    unitDisplay () {
      return this.$parent.unitDisplay(this.kpi, 'long')
    },
    filteredPeriods () {
      return this.kpi.records?.filter(record => {
        return record.period.toString().length === this.filterFrom.toString().length &&
               record.period >= this.filterFrom && record.period <= this.filterTo
      })
    }
  },
  methods: {
    updateFilterFromTo () {
      let filterStartYear = ''
      let filterEndYear = ''
      if (this.$parent.$parent.filterValidFrom && this.$parent.$parent.filterValidTo) {
        filterStartYear = this.$parent.$parent.filterValidFrom.substr(0, 4)
        filterEndYear = this.$parent.$parent.filterValidTo.substr(0, 4)
      } else if (this.$parent.filterValidFrom && this.$parent.filterValidTo) {
        filterStartYear = this.$parent.filterValidFrom.substr(0, 4)
        filterEndYear = this.$parent.filterValidTo.substr(0, 4)
      } else {
        filterStartYear = filterEndYear = new Date().getFullYear()
      }

      if (this.filterTimeDim === 'M') {
        this.filterFrom = filterStartYear + '01'
        this.filterTo = filterEndYear + '12'
      } else if (this.filterTimeDim === 'Q') {
        this.filterFrom = filterStartYear + '1'
        this.filterTo = filterEndYear + '4'
      } else {
        this.filterFrom = (Number(filterStartYear) - 2).toString()
        this.filterTo = (Number(filterEndYear) + 2).toString()
      }
      this.kpiKeyInt++
    },
    updateFilterOptions () {
      let yearStart = 2000
      const filterOptions = []
      let periodsFilterUnits = []
      if (this.filterTimeDim === 'M') {
        periodsFilterUnits = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      } else if (this.filterTimeDim === 'Q') {
        periodsFilterUnits = ['1', '2', '3', '4']
      } else {
        periodsFilterUnits = ['']
      }
      while (yearStart <= 2099) {
        periodsFilterUnits.forEach(unit => {
          filterOptions.push(yearStart.toString() + unit)
        })
        yearStart++
      }
      this.filterOptions = filterOptions
      this.kpiKeyInt++
    },
    formatPeriod (period) {
      return periodUtils.formatPeriod(period)
    }
  },
  watch: {
    kpi () {
      this.updateFilterFromTo()
    },
    filterTimeDim () {
      this.updateFilterOptions()
      this.updateFilterFromTo()
    },
    filterFrom () {
      this.kpiKeyInt++
    },
    filterTo () {
      this.kpiKeyInt++
    }
  },
  created () {
    this.updateFilterOptions()
    this.updateFilterFromTo()
  }
}
</script>

<style scoped>
.modal-dialog {
  max-width: 50vw;
}
.table-kpi-data {
  max-height: 250px;
  overflow: scroll;
}
.table-kpi-data table {
  table-layout: fixed;
}
.select-th {
  background-color: transparent;
  border: 0;
  padding: 0 25px 0 0;
  font-weight: bold;
}
</style>
