<template>
  <div class="org-modal">
    <div class="modal fade" data-bs-backdrop="static" id="orgUnitDeleteModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div v-if="elementType && (mode === 'delete' || mode === 'remove')" class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ vocabulary[elementType].text }} {{ vocabulary[mode].text }}</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="text-center my-3">
              <p v-if="elementType === 'orgUnit'" class="fw-bold">{{ this.selectedOrgUnit.name }}</p>
              <p v-else class="fw-bold">{{ this.selectedElement.firstName }} {{ this.selectedElement.lastName }}</p>
              <p>Möchten Sie {{ vocabulary[elementType].det }} {{ vocabulary[elementType].text }} wirklich {{ vocabulary[mode].text }}?</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="deleteElement()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">{{ vocabulary[mode].textC }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrgUnitDeleteModal',
  data () {
    return {
      vocabulary: {
        orgUnit: {
          text: 'Org-Einheit',
          det: 'die'
        },
        employee: {
          text: 'Mitarbeiter',
          det: 'den'
        },
        delete: {
          text: 'löschen',
          textC: 'Löschen'
        },
        remove: {
          text: 'entfernen',
          textC: 'Entfernen'
        }
      }
    }
  },
  computed: {
    selectedOrgUnit () {
      return this.$store.state.selectedOrgUnit
    },
    selectedElement () {
      return this.$parent.selectedElement
    },
    elementType () {
      return this.$parent.orgUnitModalElementType
    },
    mode () {
      return this.$parent.orgUnitModalMode
    }
  },
  methods: {
    async deleteElement () {
      try {
        this.$store.commit('SET_LOADING_STATUS', true)
        const elementId = this.$parent.orgUnitModalElementId
        const elementType = this.elementType === 'orgUnit' ? this.elementType : (this.elementType + 's')
        const dispatchFunc = this.elementType === 'orgUnit' ? 'deleteOrgUnit' : 'deleteOrgUnitElement'
        await this.$store.dispatch(dispatchFunc, { orgUnitId: this.selectedOrgUnit.id, elementType, elementId })
      } finally {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
</script>
