<template>
  <div ref="editor"></div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.snow.css'

export default {
  name: 'RTEditor',
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  mounted () {
    this.quill = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: [
          [
            { header: [1, 2, false] }, 'bold', 'italic', 'underline',
            { color: [] }, 'link', { list: 'ordered' }, { list: 'bullet' }, 'clean'
          ]
        ]
      }
    })

    this.setEditorContent(this.modelValue)
  },
  methods: {
    setEditorContent (content) {
      this.quill.setContents(this.quill.clipboard.convert({ html: content }))
    },
    getContent () {
      return this.quill.root.innerHTML
    }
  },
  watch: {
    modelValue (newValue) {
      this.setEditorContent(newValue)
    }
  }
}
</script>

<style>
.ql-toolbar {
  border-radius: 0.375rem 0.375rem 0rem 0rem;
}

.ql-container {
  height: 120px;
  background-color: #ffffff;
  border-radius: 0rem 0rem 0.375rem 0.375rem;
}

.quill-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
