<template>
  <div class="kpi-modal">
    <div class="modal fade" data-bs-backdrop="static" id="kpiDeleteModal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div v-if="elementType" class="modal-content bg-primary">
          <div class="modal-header">
            <div class="h5 modal-title text-light">{{ vocabulary[elementType].text }} löschen</div>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body bg-light">
            <div class="text-center my-3">
              <p v-if="elementType === 'kpi'" class="fw-bold">{{ selectedKPI.name }}</p>
              <p>Möchten Sie {{ vocabulary[elementType].det }} {{ vocabulary[elementType].text }} wirklich löschen?</p>
            </div>
          </div>
          <div class="modal-footer bg-light">
            <button type="button" class="btn btn-sm btn-outline-secondary" data-bs-dismiss="modal">{{ $t("general.close") }}</button>
            <button @click="deleteElement()" type="button" class="btn btn-sm btn-danger" data-bs-dismiss="modal">{{ $t("general.delete") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KPIDeleteModal',
  data () {
    return {
      vocabulary: {
        kpi: {
          text: 'KPI',
          det: 'die'
        },
        formula: {
          text: 'Formel',
          det: 'die'
        }
      }
    }
  },
  computed: {
    selectedKPI () {
      return this.$store.state.selectedKPI
    },
    elementId () {
      return this.$parent.kpiModalElementId
    },
    elementType () {
      return this.$parent.kpiModalElementType
    }
  },
  methods: {
    async deleteElement () {
      try {
        this.$store.commit('SET_LOADING_STATUS', true)
        if (this.elementType === 'kpi' && !this.selectedKPI.hasNodes) {
          await this.$store.dispatch('deleteKPI', this.selectedKPI.id)
        } else {
          await this.$store.dispatch('deleteKPIElement', { kpiId: this.selectedKPI.id, elementType: (this.elementType + 's'), elementId: this.elementId })
        }
      } finally {
        this.$store.commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
</script>
